const pcHome = () => import('@/views/pc/home/index.vue'); // 首页
const solution = () => import('@/views/pc/solution/index.vue'); // 解决方案
const secure = () => import('@/views/pc/solution/components/secure.vue'); // 解決方案 安全
const industry = () => import('@/views/pc/solution/components/industry.vue'); // 解決方案 工业
const city = () => import('@/views/pc/solution/components/city.vue'); // 解決方案 城市
const mine = () => import('@/views/pc/solution/components/mine.vue'); // 解決方案 矿山
const comprehensive = () => import('@/views/pc/solution/components/comprehensive.vue'); // 解決方案 综合
const lawEnforcement = () => import('@/views/pc/solution/components/lawEnforcement.vue'); // 解決方案 执法
const expressway = () => import('@/views/pc/solution/components/expressway.vue'); // 解決方案 高速
const power = () => import('@/views/pc/solution/components/power.vue'); // 解決方案 电力
const news = () => import('@/views/pc/news/index.vue'); // 新闻
const newDetail = () => import('@/views/pc/news/newDetail.vue'); // 新闻详情
const pcEntry = () => import('@/views/pc/pcEntry.vue'); // 入口
const qualifications = () => import('@/views/pc/qualifications/index.vue'); // 资质
const computingPower = () => import('@/views/pc/products/computingPower.vue'); // 算力
const algorithm = () => import('@/views/pc/products/algorithm.vue'); // 算法
const aiHardware = () => import('@/views/pc/products/aiHardware.vue'); // 智能硬件
const largeModel = () => import('@/views/pc/products/largeModel.vue'); // 大模型
const aboutUs = () => import('@/views/pc/aboutUs/index.vue'); // 关于我们

export default [
  {
    path: '/pc',
    name: 'pcEntry',
    component: pcEntry,
    children: [
      {
        path: 'home',
        component: pcHome,
        name: 'pcHome',
        meta: { title: '广监云-领先的AI技术服务提供商' }
      },
      {
        path: 'computingPower',
        component: computingPower,
        name: 'computingPower',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'algorithm',
        component: algorithm,
        name: 'pcAlgorithm',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'aiHardware',
        component: aiHardware,
        name: 'aiHardware',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'largeModel',
        component: largeModel,
        name: 'largeModel',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },

      {
        path: 'solution',
        component: solution,
        name: 'solution',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'secure',
        component: secure,
        name: 'secure',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'industry',
        component: industry,
        name: 'industry',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'city',
        component: city,
        name: 'city',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'mine',
        component: mine,
        name: 'mine',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'comprehensive',
        component: comprehensive,
        name: 'comprehensive',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'lawEnforcement',
        component: lawEnforcement,
        name: 'lawEnforcement',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'expressway',
        component: expressway,
        name: 'expressway',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'power',
        component: power,
        name: 'power',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },

      {
        path: 'news',
        component: news,
        name: 'pcNews',
        meta: { title: '广监云-最新动态，行业资讯，内部新闻' }
      },
      {
        path: 'newDetail',
        component: newDetail,
        name: 'newDetail',
        meta: { title: '广监云-最新动态，行业资讯，内部新闻' }
      },

      {
        path: 'qualifications',
        component: qualifications,
        name: 'qualifications',
        meta: { title: '广监云-企业资质，全面布局AI综合服务生态。同时搭载研发智库，以先进的技术实力和完善的服务体系持续推进人工智能在社会治理中的应用' }
      },

      {
        path: 'aboutUs',
        component: aboutUs,
        name: 'aboutUs',
        meta: { title: '广监云-是国内技术先进的AI技术服务提供商，致力于以科技创新推动中国新质生产力发展' }
      }
    ]
  }
];
