const mobileEntry = () => import('@/views/mobile/entry.vue'); // 入口
const mobileHome = () => import('@/views/mobile/home/index.vue'); // 首页
const moreMenu = () => import('@/views/mobile/moreMenu.vue'); // 更多菜单
const computility = () => import('@/views/mobile/computility/index.vue'); // 更多菜单
const algorithm = () => import('@/views/mobile/algorithm/index.vue'); // 更多菜单
const largescaleModels = () => import('@/views/mobile/largescale-models/index.vue'); // 更多菜单
const hardware = () => import('@/views/mobile/hardware/index.vue'); // 更多菜单
const plan = () => import('@/views/mobile/plan/index.vue'); // 更多菜单
const news = () => import('@/views/mobile/news/index.vue'); // 更多菜单
const newsHome = () => import('@/views/mobile/news/newsHome.vue'); // 新闻首页
const mobileQualifications = () => import('@/views/mobile/qualifications/index'); // 资质证书
const mobileAboutUs = () => import('@/views/mobile/aboutUs/index.vue'); // 关于我们
const mobileSolution = () => import('@/views/mobile/solution/index.vue'); // 解决方案更多

export default [
  {
    path: '/mobile',
    name: 'mobileEntry',
    component: mobileEntry,
    children: [
      {
        path: 'home',
        component: mobileHome,
        name: 'mobileHome',
        meta: { title: '广监云-领先的AI技术服务提供商' }
      },
      {
        path: 'computility',
        component: computility,
        name: 'computility',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'algorithm',
        component: algorithm,
        name: 'algorithm',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'largescale-models',
        component: largescaleModels,
        name: 'largescale-models',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },
      {
        path: 'hardware',
        component: hardware,
        name: 'hardware',
        meta: { title: '广监云-产品服务，算力，算法，大模型，智能硬件' }
      },

      {
        path: 'moreMenu',
        component: moreMenu,
        name: 'moreMenu'
      },

      {
        path: 'mobileSolution',
        component: mobileSolution,
        name: 'mobileSolution',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },
      {
        path: 'plan',
        component: plan,
        name: 'plan',
        meta: { title: '广监云-解决方案，持续打造安全应急领域人工智能解决方案，服务社会治理' }
      },

      {
        path: 'news',
        component: news,
        name: 'news',
        meta: { title: '广监云-最新动态，行业资讯，内部新闻' }
      },
      {
        path: 'newsHome',
        component: newsHome,
        name: 'newsHome',
        meta: { title: '广监云-最新动态，行业资讯，内部新闻' }
      },

      {
        path: 'mobileQualifications',
        component: mobileQualifications,
        name: 'mobileQualifications',
        meta: { title: '广监云-企业资质，全面布局AI综合服务生态。同时搭载研发智库，以先进的技术实力和完善的服务体系持续推进人工智能在社会治理中的应用' }
      },

      {
        path: 'mobileAboutUs',
        component: mobileAboutUs,
        name: 'mobileAboutUs',
        meta: { title: '广监云-是国内技术先进的AI技术服务提供商，致力于以科技创新推动中国新质生产力发展' }
      }
    ]
  }
];
