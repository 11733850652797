import Vue from 'vue';
import VueRouter from 'vue-router';

import pcRoutes from './pc';
import mobileRoutes from './mobile';

Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
// 下面这段代码主要解决这个问题 ：Uncaught (in promise) Error: Redirected when going from "/login" to "/index" via a navigation guard.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    redirect: '/pc/home'
  },
  {
    path: '/mobile',
    redirect: '/mobile/home'
  },
  ...mobileRoutes,
  ...pcRoutes
];
const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    if (to.name === 'pcHome') {
      next('/mobile/home');
      return;
    }
  } else {
    if (to.name === 'mobileHome') {
      next('/pc/home');
    }
  }
  next();
});

export default router;
