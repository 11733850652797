const state = {
  isPc: true,
  tel: '400-8789-861',
  menuList: [
    {
      title: '产品服务',
      disc: '此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案',
      sub: [
        { title: '算力', en: 'Computing power', pathName: 'computility' },
        { title: '算法', en: 'Algorithm', pathName: 'algorithm' },
        { title: '大模型', en: 'Large model', pathName: 'largescale-models' },
        { title: '智能硬件', en: 'Intelligent hardware', pathName: 'hardware' }
      ]
    },
    {
      title: '解决方案',
      disc: '此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案此处是文案',
      sub: [
        { title: '安全生产人工智能风险隐患预警', pathName: 'plan' },
        { title: '工业园区人工智能风险隐患预警', pathName: 'plan' },
        { title: '城市安全风险人工智能预警平台', pathName: 'plan' },
        { title: '矿山安全人工智能风险隐患预警', pathName: 'plan' },
        { title: '综合管廊人工智能风险隐患预警', pathName: 'plan' },
        { title: '执法检查人工智能风险隐患预警', pathName: 'plan' },
        { title: '高速公路人工智能风险隐患预警', pathName: 'plan' },
        { title: '电力行业人工智能风险隐患预警', pathName: 'plan' }
      ],
      path: '/mobile/mobileSolution'
    },
    // {
    //   title: '新闻资讯',
    //   path: '/mobile/newsHome',
    //   sub: [{ title: '新闻资讯', url: '/mobile/newsHome' }]
    // },
    {
      title: '资质证书',
      path: '/mobile/mobileQualifications',
      sub: [{ title: '资质证书', url: '/mobile/mobileQualifications' }]
    },
    {
      title: '关于我们',
      path: '/mobile/mobileAboutUs',
      sub: [{ title: '关于我们', url: '/mobile/mobileAboutUs' }]
    }
  ],
  pcMenuList: [
    {
      title: '产品服务',
      disc: '国内优质的AI服务提供商',
      sub: [
        { title: '算力', en: 'Computing power', url: '/pc/computingPower' },
        { title: '算法', en: 'Algorithm', url: '/pc/algorithm' },
        { title: '大模型', en: 'Large model', url: '/pc/largeModel' },
        { title: '智能硬件', en: 'Intelligent hardware', url: '/pc/aiHardware' }
      ]
    },
    {
      title: '解决方案',
      disc: '持续打造行业客户方案实践，服务数字中国',
      url: '/pc/solution',
      scatter: true,
      sub: [
        { title: '安全生产人工智能风险隐患预警解决方案', url: '/pc/secure' },
        { title: '工业园区人工智能风险隐患预警解决方案', url: '/pc/industry' },
        { title: '城市安全风险人工智能预警平台解决方案', url: '/pc/city' },
        { title: '矿山安全人工智能风险隐患预警解决方案', url: '/pc/mine' },
        { title: '综合管廊人工智能风险隐患预警解决方案', url: '/pc/comprehensive' },
        { title: '执法检查人工智能风险隐患预警解决方案', url: '/pc/lawEnforcement' },
        { title: '高速公路人工智能风险隐患预警解决方案', url: '/pc/expressway' },
        { title: '电力行业人工智能风险隐患预警解决方案', url: '/pc/power' }
      ]
    },
    // {
    //   title: '新闻资讯',
    //   url: '/pc/news'
    // },
    {
      title: '资质证书',
      url: '/pc/qualifications'
    },
    {
      title: '关于我们',
      url: '/pc/aboutUs'
    }
  ],
  showActive: false,
  curMenuIndex: -1 // 当前菜单的下标
};
const mutations = {
  SET_ACTIVE(state, data) {
    state.showActive = data;
  },
  REMOVE_ACTIVE(state) {
    state.showActive = false;
  },
  SET_CUR_MENU_INDEX(state, index) {
    state.curMenuIndex = index;
  },
  SET_CUR_IS_PC(state, val) {
    state.isPc = val;
  }
};
const actions = {
  setActive({ commit }) {
    commit('SET_ACTIVE', true);
  },
  removeActive({ commit }) {
    commit('REMOVE_ACTIVE');
  },
  setCurMenuIndex({ commit }, index) {
    commit('SET_CUR_MENU_INDEX', index);
  },
  setCurIsPc({ commit }, index) {
    commit('SET_CUR_IS_PC', index);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
